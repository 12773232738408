import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import { ShimmerTable } from "react-shimmer-effects";

import {isMobile, MobileView, BrowserView} from 'react-device-detect';

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";

const ContactList = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState();
  const [contacts, setContacts] = useState();
  useEffect(() => {
    async function init() {
      const result = await SparkApi.fetchContacts();
      ////console.log('result',result)

      if (result) {
        setBody(result);
        setContacts(result);
      } else {
        setBody([]);
        setContacts([]);
      }
    }
    init();
  }, []);

  const onFilterType = (event) => {
    if (event.target.value === "") {
      setBody(contacts);
    } else {
      setBody(
        contacts.filter((data) => {
          if (
            (data.recordtypeid || "").toLowerCase() ===
            (event.target.value || "").toLowerCase()
          ) {
            return data;
          }
        })
      );
    }
  };

  // Create table headers consisting of 4 columns.
  const header = [];
  if(!isMobile){
    header.push(
    {
      title: "Name",
      prop: "firstname",
      isFilterable: true,
      cell: (row) => (
        <Link to={"/contacts/" + row.id} state={row}>
          {row.firstname} {row.lastname} 
        </Link>
      ),
    },
    
    { title: "Street", prop: "street" },
    { title: "City", prop: "city", isFilterable: true },
    { title: "Email", prop: "email", isFilterable: true },
    { title: "Phone", prop: "phone", isFilterable: true },
    // { title: "Contact Type", prop: "recordtypeid", isFilterable: true },
    )
  }else{
    //for mobile device
    
    header.push(
      {
        title: "Info",
        prop: "name",
        isFilterable: true,isSortable: true,
        cell: (row) => (
          <div className="mobilecard" >
           <Link to={"/contacts/" + row.id} state={row} style={{width: "100%"}}>
          {row.firstname} {row.lastname} 
        </Link>
        
        <span><i class="fa-solid fa-phone"></i> {row.phone}</span>
            <span style={{width: "80%"}}><i class="fa-solid fa-envelope"></i> {row.email}</span>
            
          </div>
        ),
      },
      )
  }
  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const createContact = () => {
    navigate(`/contacts/e`);
  };

  return (
    <Row className="g-0">
     
      <Col lg={12} className="px-4">
        {body ? (
          <DatatableWrapper
            body={body}
            headers={header}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 10,
                options: [5, 10, 15, 20],
              },
            }}
          >
            <Row className="mb-4">
              <Col
                xs={12}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Filter />
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-start align-items-start"
              >
                <PaginationOptions labels={labels} />
                {/* <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">
                  <Form.Select aria-label="Enter status" name="recordtypeid" onChange={onFilterType}>
                    <option value="">--Select Type--</option>
                    <option value="Doctor">Doctor</option>
                    <option value="Patient">Patient</option>
                    <option value="Staff">Staff</option>
                  </Form.Select>
                </Form.Group> */}
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
                <Button
                  className="btn-sm"
                  variant="outline-primary"
                  onClick={() => createContact(true)}
                >
                  New Contact
                </Button>
              </Col>
            </Row>
            <Table striped className="data-table" responsive = "sm">
              <TableHeader />
              <TableBody />
            </Table>
            <Pagination />
          </DatatableWrapper>
        ) : (
          <ShimmerTable row={10} col={8} />
        )}
      </Col>
      <Col lg={2}></Col>
    </Row>
  );
};

export default ContactList;
