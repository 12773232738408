import React, { useState } from 'react'
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Confirm = (props) => {
  const [show, setShow] = useState(true);

  const deleteContact = () => {
    props.deleteContact();
  }

  const deletepayment = () => {
    props.deletepayment();
  }
  const deleteMedicaltest = () => {
    props.deleteMedicaltest();
  }
  const deleteTask = () => {
    props.deleteTask();
  }

  const deleteLead = () => {
    props.deleteLead();
  }


  const deleteReport = () => {
    props.deleteReport();
  }

  const deleteRecord = () => {
    props.deleteRecord();
  }

  const deleteFile = () => {
    props.deleteFile();
  }

  const deletePurchaseOrder = () => {
    props.deletePurchaseOrder();
  }

  const deleteOrder = () => {
    props.deleteOrder();
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <Alert show={show} variant="danger" className='mb-0'>
        <Alert.Heading>{props.title}</Alert.Heading>
        <p>
          {props.message}
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          {props.table === "contact" &&
            <Button onClick={props.deleteContact} variant="danger" className='mx-2'>
              Yes
            </Button>
          }
          {props.table === "lead" &&
            <Button onClick={props.deleteLead} variant="danger" className='mx-2'>
              Yes
            </Button>
          }

          {props.table === "general" &&
            <Button onClick={props.deleteRecord} variant="danger" className='mx-2'>
              Yes
            </Button>
          }

          {props.table === "task" &&
            <Button onClick={props.deleteTask} variant="danger" className='mx-2'>
              Yes
            </Button>
          }

          {props.table === "report" &&
            <Button onClick={props.deleteReport} variant="danger" className='mx-2'>
              Yes
            </Button>
          }



          {props.table === "payment" &&
            <Button onClick={props.deletepayment} variant="danger" className='mx-2'>
              Yes
            </Button>
          }


          {props.table === "file" &&
            <Button onClick={props.deleteFile} variant="danger" className='mx-2'>
              Yes
            </Button>
          }
        {props.table === "product" &&
            <Button onClick={props.deleteProduct} variant="danger" className='mx-2'>
              Yes
            </Button>
          }

          {props.table === "pricebook" &&
            <Button onClick={props.deletePricebook} variant="danger" className='mx-2'>
              Yes
            </Button>
          }
          {props.table === "medicaltest" &&
            <Button onClick={props.deleteMedicaltest} variant="danger" className='mx-2'>
              Yes
            </Button>
          }
          {props.table === "commission" &&
            <Button onClick={props.deleteCommissions} variant="danger" className='mx-2'>
              Yes
            </Button>
          }
          {props.table === "package" &&
            <Button onClick={props.deletePackage} variant="danger" className='mx-2'>
              Yes
            </Button>
          }
           {props.table === "incident" &&
            <Button onClick={props.deleteIncident} variant="danger" className='mx-2'>
              Yes
            </Button>
          }
          <Button onClick={props.onHide} variant="light" className="text-">
            No
          </Button>
        </div>
      </Alert>
    </Modal>
  )
}

export default Confirm