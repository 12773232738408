import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Nav, Row } from "react-bootstrap";
import Confirm from "./Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import TaskEdit from "./TaskEdit";
import { Link } from "react-router-dom";
import helper from "./common/helper";

import * as constants from '../constants/CONSTANT';

const ContactView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  //const contact = location.state;
  const [contact, setContact] = useState(location.state ? location.state : {});
  const [relatedListPatientTests, setRelatedListPatientTests] = useState(true);
  const [relatedListFiles, setRelatedListFiles] = useState(true);
  const [relatedListLeadtTests, setRelatedListLeadtTests] = useState(true);
  const [modalShowMedicaltestitem, setModalShowMedicaltestitem] =    useState(false);
  const [refreshBusinessList, setRefreshBusinessList] = useState();
  const [modalShowFile, setModalShowFile] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [reletedListTask, setReletedListTask] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [reletedListBusiness, setRelatedListBusiness] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [accountdata, setAccountData] = useState({});
  const [refreshTaskList, setRefreshTaskList] = useState();

  const [modalShow, setModalShow] = useState(false);
  ////console.log("contact=>" + JSON.stringify(contact));

  useEffect(() => {
    fetchContact();
    
  }, []);

  const submitTasks = () => {
    setModalShowTask(false);
    setRefreshTaskList(Date.now());
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setReletedListTask(true);
      setRelatedListFiles(false);
      setRelatedListBusiness(false);
    } else if (key === "files") {
      setRelatedListFiles(true);
      setReletedListTask(false);
      setRelatedListBusiness(false)
    }else if (key === "business") {
      setRelatedListBusiness(true);
      setReletedListTask(false);
      setRelatedListFiles(false);
    }
  };

  const submitFiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    setModalShowTask(false);
  };

  const fetchContact = () => {
    // Coming from Email
    if (!contact.id && location.hasOwnProperty("pathname")) {
      contact.id = location.pathname.split("/")[2];
      //console.log("pathnam===" + contact.id);
    }
    async function initContact() {
      let result = await SparkApi.fetchContact(contact.id);
      //console.log('result:', result);
      if (result) {
        setContact(result);
      } else {
        setContact({});
      }
    }
    initContact();
  };

  const deleteContact = async () => {
    const result = await SparkApi.deleteContact(contact.id);
    if (result.success) navigate(`/contacts`);
  };

  const editContact = () => {
    navigate(`/contacts/${contact.id}/e`, { state: contact });
  };

  return (
    <div>
      {contact && (
        <Container>
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteContact={deleteContact}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="contact"
            />
          )}
           <Row className="view-form">
         
         <Col lg={11} className="pb-3 pt-2">
       <Link className="nav-link" to="/contacts">
         Home <i className="fa-solid fa-chevron-right"></i> <div style={{color: "#23468c", display : "inline"}}>Contacts</div>
       </Link>
     </Col>
     <Col></Col>
           </Row>
          <Row className="view-form">
            <Col></Col>
            <Col lg={8}>
              <Row className="view-form-header align-items-center">
                <Col lg={8}>
                  Contact
                  <h5>
                    {contact.salutation} {contact.firstname} {contact.lastname}
                  </h5>
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => editContact(true)}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>
                  {helper.checkPermission(constants.MODIFY_ALL) && <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={() => setModalShow(true)}
                  >
                    Delete
                  </Button>}
                </Col>
              </Row>
              <Row className="py-3 ibs-edit-form">
                <Col lg={12}>
                  <label>Account Name</label>
                  <Link to={"/accounts/" + contact.accountid} state={{id: contact.accountid}}>
                    <span>{contact.accountname}</span>
                  </Link>
                </Col>
                <Col lg={6}>
                  <label>Name</label>
                  <span>
                    {contact.salutation} {contact.firstname} {contact.lastname}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Email</label>
                  <span>{contact.email}</span>
                </Col>
                <Col lg={6}>
                  <label>Phone</label>
                  <span>{contact.phone}</span>
                </Col>
                <Col lg={6}>
                  <label>Title</label>
                  <span>{contact.title}</span>
                </Col>

                <Col lg={6}>
                  <label>City</label>
                  <span>{contact.city}</span>
                </Col>
                <Col lg={6}>
                  <label>State</label>
                  <span>{contact.state}</span>
                </Col>
                <Col lg={6}>
                  <label>Pincode</label>
                  <span>{contact.pincode}</span>
                </Col>
                <Col lg={6}>
                  <label>Country</label>
                  <span>{contact.country}</span>
                </Col>
                <Col lg={6}>
                  <label>Street</label>
                  <span>{contact.street}</span>
                </Col>
              </Row>
            </Col>
            <Col></Col>
          </Row>
          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="files"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                {/* <Tab eventKey="patienttests" title="Medical Tests"></Tab> */}
                <Tab eventKey="files" title="Files"></Tab>
                <Tab eventKey="tasks" title="Tasks"></Tab>
                <Tab eventKey="business" title="Business"></Tab>
              </Tabs>

              {relatedListFiles && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  New File
                </Button>
              )}
              

              {reletedListTask && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowTask(true)}
                >
                  New Task
                </Button>
              )}
              {modalShowTask && (
              <TaskEdit
              show={modalShowTask}
              onHide={() => setModalShowTask(false)}
              parentid={contact.id}
              table="account"
              submitTasks={submitTasks}
              />
            )}
            </Card.Header>
            <Card.Body>
              {/* {relatedListPatientTests && <RelatedListPatientTests parent={contact}/>} */}

            
            {/* {reletedListTask ? <RelatedListTask refreshTaskList={refreshTaskList} parent={contact}/> : ""}
            {reletedListBusiness ? <RelatedListBusiness refreshTaskList={refreshBusinessList} parent={contact} /> : ""} */}
            </Card.Body>
          </Card>
        </Container>
      )}
    </div>
  );
};
export default ContactView;
