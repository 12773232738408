import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { collection, getDocs } from 'firebase/firestore';

const AllJourneys = () => {
  const [journeys, setJourneys] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchJourneys = async () => {
      try {
        const journeysCollection = collection(db, 'journeys');
        const journeySnapshot = await getDocs(journeysCollection);
        const journeyList = journeySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setJourneys(journeyList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching journeys:', error);
        setLoading(false);
      }
    };

    fetchJourneys();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }
 
  return (
    <div>
      <h1>All Journeys</h1>
      <ul>
        {journeys.map((journey) => (
          <li key={journey.id}>
            <p>Field Person ID: {journey.fieldPersonId}</p>
            <p>Start Time: {journey.startTime?.toDate().toString()}</p>
            <p>End Time: {journey.endTime?.toDate().toString()}</p>
            <p>Locations: {JSON.stringify(journey.locations)}</p>
          </li>
        ))}
      </ul>
    </div> 
  );
};

export default AllJourneys;
