import React,{useEffect, useState} from "react"
import { Button, Col, Container,Form, Spinner , Row, Alert  } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import { Shimmer } from "react-shimmer";

const UserTracking = () => {

    const location = useLocation();
    const[latitude, setLatitude] = useState();
    const[longitude, setLongitude] = useState();
    const[remarks, setRemarks] = useState();
    const[result, setResult] = useState()
    const[buttonDisabled, setButtonDisabled] = useState(false)
    const[locationRecord, setLocationRecord] = useState({});
    const[currentrecord, setCurrentrecord] = useState();
    const[spinner, setSpinner] = useState(false);
    const[alert, setAlert] = useState(null);
    const[address, setAddress] = useState()
  

    useEffect(()=>{
      if(alert){
        setTimeout(()=>{
         // setAlert(null);
        },3000)
      }
    },[alert])

    useEffect(() => {
        
        /*////console.log('currentrecord use effecct =',);
        currentrecord && Promise.resolve(currentrecord).then((record) => {
            ////console.log('record use effecct =',record);
            if(record && record.hasOwnProperty('errors')){
                setButtonDisabled(false)
            } else if(record.logindatetime != null && record.logoutdatetime == null){
                setResult(record)
                setRemarks(record.remarks)
                setButtonDisabled(true)
            } else if (record.logindatetime.length && record.logoutdatetime.length){
                setResult(record)
                setRemarks('')
                setButtonDisabled(false)
            }
        })*/
    },[])

    useEffect(() => {
      async function init() {
        let userdata = await SparkApi.getCurrentUserTrackingRec();
        ////console.log('userdata:', userdata);
        setCurrentrecord(userdata);
        setLocationRecord(userdata);
        if(userdata.logindatetime != null && userdata.logoutdatetime == null){
          setButtonDisabled(true)
          setRemarks(userdata.remarks)
        }else if (userdata.logindatetime.length && userdata.logoutdatetime.length){
          setRemarks(userdata.remarks)
          setButtonDisabled(false)
      }
      }
      init();

        //setSpinner(true);
        if(!window.myMap){     
            ////console.log('!window.myMap')       
            window.myMap = myMap;
            const googleMapScript = document.createElement('script');
            googleMapScript.src=`https://maps.googleapis.com/maps/api/js?key=AIzaSyBZsH0US1O6fSogoqBTUpUkvEWqs-rYMlY&callback=myMap`;
            googleMapScript.async = true;
            window.document.body.appendChild(googleMapScript); 
            //////console.log('refresh:');
        }else{
            ////console.log('window.myMap')      
            myMap();  
        }
        
        //setCurrentrecord({errors:'No Data'});
        //////console.log('currentRec => ', currentRec);
      }, []);

      
      const myMap = async () =>{ 
        ////console.log('calling my map=====')
        
       
     
        var mapProp= {  
          center:new window.google.maps.LatLng( latitude?latitude:12,longitude?longitude:12),
          zoom:14,  
        };

        const locate = window.navigator && window.navigator.geolocation;
        const currentCoords = {lat : 0, lng : 0};

        if(locate){ 
            ////console.log('in locate==>')
            locate.getCurrentPosition((position) => {
                ////console.log('in position', position)
                currentCoords.lat= position.coords.latitude;
                currentCoords.lng= position.coords.longitude;
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
                
               // setSpinner(false)
                mapProp = {
                    center:new window.google.maps.LatLng(position.coords.latitude,position.coords.longitude),
                    zoom:14,
                }

                var map = new window.google.maps.Map(document.getElementById("googleMap"), mapProp);
                ////console.log('location => ',location)
                var geocoder = new window.google.maps.Geocoder();      
                
                geocoder.geocode( { location: currentCoords}, function(results, status) {
                    ////console.log(results)
                    let cur_address =  results && results.length > 0 ? results[0].formatted_address : '';
                    setAddress(cur_address);
                    var marker = new window.google.maps.Marker({
                        map: map,
                        position: {lat:currentCoords.lat, lng:currentCoords.lng},  
                        title: cur_address,
                        content : cur_address,
                    });

                    var infowindow = new window.google.maps.InfoWindow({
                      content: cur_address
                  });
                    marker.addListener("click", () => {
                        infowindow.open({
                            anchor: marker,
                            map,
                        });
                    });
                    
            });  

              }, (error) => {
                ////console.log('error in location -> ', error) 
              },{maximumAge:10000, timeout:5000, enableHighAccuracy: true}
              )
        }

        setTimeout(() => {
            ////console.log('mapProp => ', mapProp)
            
            
        },500)
        
        }



    const setLatLongJs = async (checkIn) => {
        const locate = window.navigator && window.navigator.geolocation;
        if(locate){
            ////console.log('in locate==>');
            ////console.log('lat lon log => ', latitude + ' ' + longitude);
            locate.getCurrentPosition(async (position)  =>  {
                ////console.log('in position', position);    
                if(checkIn){
                    let res = await SparkApi.createCheckInRecord({'logindatetime':new Date(), loginlattitude: position.coords.latitude.toString(), loginlongitude: position.coords.longitude.toString(), remarks, location: address});
                    
                    
                    setTimeout(() => {
                        
                        if(res){
                          setButtonDisabled(true);
                          setLocationRecord(res);
                          setAlert({message: 'You have been Successfully Checked In.', type:'success'});
                        } else {
                          setAlert({message: 'Somthing Went Wrong.', type:'danger'});
                        }
                    },'500')
                
                } else {
                    ////console.log('false',result);
                    
                    let res;
                      ////console.log('locationRecord:', locationRecord);
                   
                        res = await SparkApi.handlCheckOut({...locationRecord, 'logoutdatetime':new Date(), 'logoutlattitude': position.coords.latitude.toString(), 'logoutlongitude': position.coords.longitude.toString(),  'remarks':remarks})
                        ////console.log('res:', res);
                        setTimeout(() => {
                            setRemarks("");
                            
                            if(res){
                              setButtonDisabled(false);
                              setAlert({message: 'You have been Successfully Checked out.', type:'danger'})
                            } else {
                              setAlert({message: 'Somthing Went Wrong.', type:'danger'})
                            }
                        },'500')
                     
                     
                  }
                }, (error) => {
                ////console.log('error in location -> ', error) 
              })
        }
    }   

    const handleCheckIn = async () => {
        setLocationRecord({});
        setLatLongJs(true);
    }

    const handleCheckOut = async () => {
        setLatLongJs(false);
        
    }

    const handleremarkChange = (e) => {
        ////console.log(e.target.value)
        setRemarks(e.target.value);
    }

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    }

  return (
    <div className='p-4'>
      <Container >
        <Row className="view-form">
        <Col lg={8}>
          
            <Row className="">
            {alert && <Alert variant={alert.type} onClose={() => setAlert(false)} style={{width:'100%',padding:'15px',margin: "0px 0px 5px 0px",fontWeight:'bold',textAlign:'center'}}>
            {alert.message}
          </Alert>}
                {spinner && <Shimmer  height={500} ></Shimmer>}
                {!spinner && <div id="googleMap" style={{width:'100%',height:'300px',border:'1px solid black'}}>

                </div>}
            </Row>
            <Row>
            <Form.Group className="" style={{padding:'0'}} controlId="formAddress">
                  <Form.Label className="form-view-label" htmlFor="formAddress">Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    placeholder="Enter Address"
                    value={address}
                    onChange={handleAddressChange}
                  />

                </Form.Group>
            </Row>
            <Row>
            <Form.Group className="" style={{padding:'0'}} controlId="formBasicFirstName">
                  <Form.Label
                    className="form-view-label"
                    htmlFor="formBasicFirstName"
                  >
                    Remarks
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="remarks"
                    value={remarks}
                    placeholder="Enter remarks.."
                    onChange={handleremarkChange}
                  />
                  {/* {errors.name && touched.name ? (
                    <p className="form-error" style={{ color: "red" }}>
                      {errors.name}
                    </p>    
                  ) : null} */}
                </Form.Group>
            </Row>
         
                <Row className="my-3">
                    <Col>
                    <Button
                    disabled={buttonDisabled}
                    variant='success'
                    onClick={() => handleCheckIn() }
                    >
                        Check-In
                    </Button>
                    </Col>
                    <Col>
                    <Button
                    disabled={!buttonDisabled}
                    variant='danger'
                    onClick={() => handleCheckOut() }
                    >
                        Check-Out
                    </Button>
                    </Col>
                </Row>
          
        </Col>
        <Col lg-4>
          
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default UserTracking;