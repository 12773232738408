import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import { Button, Col, Row } from 'react-bootstrap';
import SparkApi from "../api/SparkApi";

const LocationTracker = ({ fieldPersonId }) => {
  const [tracking, setTracking] = useState(false);
  const [journeyId, setJourneyId] = useState(null);
  const[locationData, setLocationData] = useState(null);
  const[started, setStarted] = useState(false);
  const [path, setPath] = useState([{lat: 26.472462371379574, lng: 74.63440189278118}]);

  const mapContainerStyle = {
    width: '100%',
    height: '100vh',
  };
  
  const options = {
    strokeColor: '#0f53ff',
  strokeOpacity: 1,
  strokeWeight: 8,
  fillColor: '#0f53ff',
  fillOpacity: 1,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
  };

  const startJourney = async () => {
    let data = {
        fieldPersonId,
        startTime: new Date(),
        endTime: null, 
        locations: []
      }
      
    localStorage.setItem('journey1', JSON.stringify(data))
    const result = await SparkApi.createJourney(data);
    if (result) {
        console.log(result)
        data.id = result;
        console.log(data)
        setLocationData({ ...data, ['id']: result });
    }
    setJourneyId('journey1');
    setTracking(true);
    setStarted(true)
  }; 
 
  const endJourney = async () => { 
    let d = localStorage.getItem('journey1');
    d = JSON.parse(d);
    console.log(locationData)
    if (locationData) {
        locationData.endTime =new Date();
      localStorage.setItem('journey1', JSON.stringify(locationData))
      const result = await SparkApi.saveJourney(locationData);
    if (result) {
        console.log(result)
    }
      setLocationData({})
      setTracking(false);
      setJourneyId(null);
    }
    setStarted(false)
  };

  const geoerror = (err) => {
    console.error(`ERROR(${err.code}): ${err.message}`);
  }
  useEffect(() => {
    if (tracking) {
      const watchId = navigator.geolocation.watchPosition(
        async (pos) => {
          const { latitude, longitude } = pos.coords;
        //   let d = localStorage.getItem('journey1');
        // d = JSON.parse(d);
        let timestamp = new Date();
        let data = locationData;
        if (data) {
          const filtered = data.locations.filter((plc) => {
            if(plc.latitude === latitude && plc.longitude === longitude)
              return true;

          }
            
        );

        // console.log('filterered', filtered)
            if(filtered.length === 0){
            data.locations.push({latitude, longitude, timestamp})
            // localStorage.setItem('journey1', JSON.stringify(d))
            
            let newPath= data.locations.map(loc => ({
                lat: loc.latitude,
                lng: loc.longitude,
              }));
            

              setPath(newPath);

            setLocationData(data);
            console.log(data)
            const result = await SparkApi.saveJourney(data);
            if (result) {
              console.log(result)
            }
    
      //navigate(`/contacts/${result.id}`, { state: result });
    }
        }
      
        },
        (err) => console.error(err),
        { enableHighAccuracy: false, maximumAge: 10000, timeout: 30000 }
      );

      return () => navigator.geolocation.clearWatch(watchId);
    }
  }, [tracking, journeyId]);

  return (
    <Row>
        <Col lg={6}>
    <div className='pt-2'>
        <Button onClick={startJourney} className='mx-2' disabled={started}>Start Journey</Button>
   
      <Button onClick={endJourney} variant="danger" disabled={!started}>End Journey</Button>
     
      {/* <div>Name</div><div>{locationData?.startTime}</div>
      <div>Name</div><div>{locationData?.endTime}</div> */}
      {/* <div>Locations</div><ol>
      {locationData?.locations && locationData.locations.map(reptile => (
        <li key={reptile.latitude}>{reptile.latitude}, &nbsp; {reptile.longitude}, &nbsp; {reptile.timestamp}</li>
      ))}
    </ol> */}
    </div>
    </Col>
    <Col lg={6} className='my-2'>
<LoadScript googleMapsApiKey="AIzaSyDtztwOW6bufAlbBWfO1k5FdZTkpUP_gmI">
<GoogleMap
  mapContainerStyle={mapContainerStyle}
  zoom={18}
  center={path.length ? path[0] : { lat: 0, lng: 0 }}
  
>
  <Polyline path={path} options={options} />
  {path.length >=1  && <Marker position={path[path.length-1]} icon={'/sustainable-travel.gif'}/>}
</GoogleMap>
</LoadScript>
</Col>
</Row>
  );
};

export default LocationTracker;
