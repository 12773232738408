/** @format */

import Sidebar from "./Sidebar"
import Header from "./Header";
import { Outlet } from "react-router-dom";
import { React, useEffect } from "react";
const Main = ({ socket }) => {

  useEffect(() => {

  }, []);

  return (
    <div className="wrapper">
      <Sidebar />
      <div id="content">
        <Header socket={socket}/>
        <Outlet />
      </div>
    </div>

  );
};

export default Main;
