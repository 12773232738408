import logo from "./logo.svg";

import "./App.css";
import "./Sidebar.css"
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import { AccordionCollapse, Alert, Container } from "react-bootstrap";

import React, { useEffect, useState } from "react";


import ContactList from "./components/ContactList";
import ContactView from "./components/ContactView";
import ContactEdit from "./components/ContactEdit";

import * as constants from './constants/CONSTANT';
import ChangePassword from "./components/ChangePassword";

import Calendar from "./components/Calendar";


import PubSub from 'pubsub-js';
import { Toast, ToastContainer } from "react-bootstrap";
import UserList from "./components/UserList";
import UserEdit from "./components/UserEdit";
import UserView from "./components/UserView";
import UserTracking from "./components/UserTracking";

import Notification from "./components/Notification";

import jwt_decode from "jwt-decode";
import io from "socket.io-client";
import Main from "./components/layout/Main";

import Dashboard from "./components/Dashboard";
import AdminDashboard from "./components/AdminDashboard";
import LocationTracker from "./components/LocationTracker";
import AllJourneys from "./components/AllJourneys";


const ENDPOINT = constants.SOCKET_BASE_URL;


function App() {
  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState('Confirmation');
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [permissions, setPermissions] = useState();
  const [connectedSocket, setConnectedSocket] = useState();

  const mySubscriber = (msg, data) => {

    switch (msg) {
      case 'RECORD_SAVED_TOAST':
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant('success')
        ////console.log('mytopic: RECORD_SAVED_TOAST'); // add your desired log message
        break;
      case 'RECORD_ERROR_TOAST':
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant('danger')
        ////console.log('mytopic: RECORD_ERROR_TOAST'); // add your desired log message
        break;
      default:
        ////console.log('mytopic: default'); // add your desired log message
        break;
    }
  };
  useEffect(() => {
    //dispatch(fetchAccounts());
    PubSub.subscribe('RECORD_SAVED_TOAST', mySubscriber);
    PubSub.subscribe('RECORD_ERROR_TOAST', mySubscriber);
    try {
      if (localStorage.getItem('token')) {
        let user = jwt_decode(localStorage.getItem('token'));
        setUserInfo(user);

        var perm = user.permissions.map(function (obj) {
          return obj.name;
        }).join(';')
        setPermissions(perm);


        //socket
        console.log(ENDPOINT);

        let socket = io(ENDPOINT, {     // note changed URL here
          path: '/socket.io',
          transports: ['websocket'],
        });
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to`,err);
        });
        console.log(socket);
        socket.emit("setup", user);
        socket.on("connect", (msg) => {
          console.log('connected client')
          setConnectedSocket(socket);
        })
        



      }
    } catch (error) {
      console.log('error' , error)
    }





  }, []);

  return (
    <>
      <ToastContainer className="p-3" position='top-center'>
        <Toast show={modalShow} onClose={() => setModalShow(false)} delay={3000} bg={variant} className="text-white" autohide>

          {variant === 'success' ?
            <div className="p-1 m-1" style={{ backgroundColor: '#198754', color: 'white' }}  >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i className="fa-solid fa-xmark text-white float-right" style={{ float: 'right' }} role='button' onClick={() => setModalShow(false)}></i>
            </div>
            :
            <div className="p-1 m-1" style={{ backgroundColor: '#DC3545', color: 'white' }}  >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i className="fa-solid fa-xmark text-white float-right" style={{ float: 'right' }} role='button' onClick={() => setModalShow(false)}></i>
            </div>
          }

          <Toast.Body>{message instanceof Array ? message.map((item) => {
            return <span>{item.msg}</span>
          }) : message instanceof Object ? <span>{message.detail}</span> : <span>{message}</span>}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          
          <Route path="/" element={<Main socket={connectedSocket} />}>
            <Route
              index
              element={

                <Home />

              }
            />
            
            {/******** Show an Lead By Id *******/}

     
            <Route
              path="/tracker"
              element={

               
                  <LocationTracker fieldPersonId="aslambari"/> 

              }
            />

<Route
              path="/trackerapp"
              element={

                permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0
                  || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
                  <AdminDashboard /> : 'You have No permission for contact'

              }
            />

            
<Route
              path="/alljourneys"
              element={

                permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0
                  || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
                  <AllJourneys /> : 'You have No permission for contact'

              }
            />


            <Route
              path="/maintanance"
              element={
                <>
                  <div className="wrapper">

                    <div id="content">

                      <Notification />
                    </div>
                  </div>
                </>
              }
            />

           
           
            {/**** Users Section */}

            <Route
              path="/users"
              element={

                permissions && permissions.indexOf(constants.MODIFY_ALL) >= 0 ?
                  <UserList />
                  : <Alert error-alert variant='danger' className="alert error-alert">
                    <i class="fa-solid fa-triangle-exclamation mx-2"></i>You haven’t have any access
                  </Alert>

              }
            />

            <Route
              path="/meetings"
              element={

                <Calendar />

              }
            />

            <Route
              path="/meetings/:today"
              element={

                <Calendar />

              }
            />


            {/******** Edit Profile *******/}
           


            {/*  Create Lead  */}

            <Route
              path="users/e"
              element={

                <UserEdit />

              }
            />
            {/******** Update Users  *******/}
            <Route
              path="users/:id/e"
              element={

                <UserEdit />

              }
            />


            {/******** Show an User By Id *******/}
            <Route
              path="users/:id"
              element={

                <UserView />

              }
            />

           
            {/******** Crate a Contact *******/}
            <Route
              path="/contacts/e"
              element={

                permissions && (permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
                  <ContactEdit /> : 'You have no permission to access account'

              }
            />
            {/******** Show All Contacts *******/}
            <Route
              path="/contacts"
              element={

                permissions && (permissions.indexOf(constants.VIEW_CONTACT) >= 0
                  || permissions.indexOf(constants.MODIFY_ALL) >= 0) ?
                  <ContactList /> : 'You have No permission for contact'

              }
            />
            {/******** Show a Contact By Id *******/}
            <Route
              path="contacts/:id"
              element={

                <ContactView />

              }
            />
            {/******** Edit Contact By Id *******/}
            <Route
              path="contacts/:id/e"
              element={

                <ContactEdit />

              }
            />

            <Route
              path="/changepassword"
              element={

                <ChangePassword />

              }
            />
            


            <Route
              path="/usertracking"
              element={

                <UserTracking />

              }
            />

            

            {/*Start updated by Pooja  Vaishnav */}
            {/******** Create Product  *******/}
            
           
           

          
            <Route
              path="/dashboard"
              element={
                <Dashboard />
              }
            />

            
            {/* <Route path="dashboards/:id" element={<DashboardView />} />
            <Route path="dashboards/:id" element={<DashboardView />} />
            <Route path="dashboards/:id/e" element={<DashboardEdit/>}></Route> */}



          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
