import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import { db } from './firebase';
import { doc, onSnapshot, collection } from 'firebase/firestore';
import { Button } from 'react-bootstrap';
import SparkApi from "../api/SparkApi";


const mapContainerStyle = {
  width: '100vw',
  height: '100vh',
};

const options = {
  strokeColor: '#0f53ff',
  strokeOpacity: 1,
  strokeWeight: 8,
  fillColor: '#0f53ff',
  fillOpacity: 1,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
};

const AdminDashboard = () => {
  const [path, setPath] = useState([{lat: 26.472462371379574, lng: 74.63440189278118}]);
  const [journeyId, setJourneyId] = useState(null); // You may need to dynamically set this
  const [recordId, setRecordId] = useState();

  useEffect(() => {
    async function getAlerts() {
      const result = await SparkApi.fetchJourneyById(recordId ? recordId : 'recent');
    if (result) {
        console.log(result)
        let newPath= result.locations.map(loc => ({
          lat: loc.latitude,
          lng: loc.longitude,
        }));
      

        setPath(newPath);
    }
    }
    getAlerts();
    const interval = setInterval(() => getAlerts(), 10000)
        return () => {
          clearInterval(interval);
        }
  }, []);

  const handleChange = (e) => {
    setRecordId(e.target.value);
  };

  const loadJourney = async (e) => {
    
    const result = await SparkApi.fetchJourneyById(recordId ? recordId : 'recent');
    if (result) {
        console.log(result)
        let newPath= result.locations.map(loc => ({
          lat: loc.latitude,
          lng: loc.longitude,
        }));
      

        setPath(newPath);
    }
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyDtztwOW6bufAlbBWfO1k5FdZTkpUP_gmI">
      <input type='text' name="recordId" value={recordId} onChange={handleChange}/> <Button onClick={loadJourney}>Load Journey</Button>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={16}
        center={path.length ? path[0] : { lat: 0, lng: 0 }}
      >
        <Polyline path={path} options={options} />
        {path.length >=1  && <Marker position={path[path.length-1]} icon={'/sustainable-travel.gif'}/>}
      </GoogleMap>
    </LoadScript>
  );
};

export default AdminDashboard;
