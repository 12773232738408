import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import authApi from "../api/authApi";
import SparkApi from "../api/SparkApi";
import * as constants from '../constants/CONSTANT';
import jwt_decode from "jwt-decode";
import {
  LoginSocialFacebook,
  LoginSocialGoogle,
  IResolveParams
} from "reactjs-social-login";

import {
  FacebookLoginButton,
  GoogleLoginButton
 
} from 'react-social-login-buttons';

const Login = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [show, setShow] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [validated, setValidated] = useState(true);

  const clientid = "722921122303-0mlk9uoi35a6b41008tn4m1mdr1k3oo8.apps.googleusercontent.com";
  const secret = "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";
 
  const checkRequredFields = () => {
    if (
      credentials.email &&
      credentials.email.trim() !== "" &&
      credentials.password &&
      credentials.password.trim() !== ""
      
    ) {
      return false;
    }
    return true;
  };

  const handleSocialLogin = async (response) => {
    // console.log(response.credential);
    // console.log(jwt_decode(response.credential));

    const result = await authApi.social_login(response, response.access_token);
    if (result.success) {
      //console.log(result.authToken)
      localStorage.setItem("token", result.authToken);

      setTimeout(async function(){
        let notifications =  await SparkApi.fetchSystemNotifications();
        //console.log('notifications' , notifications);
        if(notifications){
          //console.log(notifications);
          notifications.forEach(function(rec){
            if(rec.type === 'SYSTEM_MAINTANANCE'){
              localStorage.setItem("notifications", JSON.stringify(notifications));
              
              return;
            }
          });
        }else{
          localStorage.removeItem("notifications");
        }
      }, 1000)
      

      // let data =  await authApi.fetchMyImage();
      // ////console.log('data',data)
      // if(data)
      //   localStorage.setItem("myimage", window.URL.createObjectURL(data));
      // else
      //   localStorage.setItem("myimage", "/img_avatar.png");

      localStorage.setItem('product_category',JSON.stringify(constants.Product_CATEGORY_VALUES));
      localStorage.setItem('product_subcategory',JSON.stringify(constants.Product_SUBCATEGORY_VALUES));
      
      let settingResult = await SparkApi.fetchCompanySetting('lead_status_setting');
      if(settingResult && settingResult.setting){
        ////console.log('result status setting',settingResult.setting.configuration);
        localStorage.setItem('lead_status',settingResult.setting.configuration);

      }else{
        localStorage.setItem('lead_status',JSON.stringify(constants.LEAD_STATUS_VALUES));

      }
      //navigate("/");
      window.location.href = "/";
    } else {
      setShow(true);
      setErrorMessage(result.errors);
    }
  }

  useEffect(() => {
    
    // setTimeout(function(){
    //   window.google.accounts.id.initialize({
    //     client_id :clientid,
    //     callback: handleSocialLogin
  
    //   })
  
    //   window.google.accounts.id.renderButton(
    //     document.getElementById("googlebutton"), {theme: "outline", size: "large"}
    //   )

    //   window.google.accounts.id.prompt();
  
    // }, 1000)
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    ////console.log(credentials);

    if (checkRequredFields()) {
      setValidated(false);
      return;
    }

    const result = await authApi.login(credentials.email, credentials.password);
    if (result.success) {
      //console.log(result.authToken)
      localStorage.setItem("token", result.authToken);

      let notifications =  await SparkApi.fetchSystemNotifications();
      //console.log('notifications' , notifications);
      if(notifications){
        //console.log(notifications);
        notifications.forEach(function(rec){
          if(rec.type === 'SYSTEM_MAINTANANCE'){
            localStorage.setItem("notifications", JSON.stringify(notifications));
            
            return;
          }
        });
      }else{
        localStorage.removeItem("notifications");
      }

      // let data =  await authApi.fetchMyImage();
      // ////console.log('data',data)
      // if(data)
      //   localStorage.setItem("myimage", window.URL.createObjectURL(data));
      // else
      //   localStorage.setItem("myimage", "/img_avatar.png");

      localStorage.setItem('product_category',JSON.stringify(constants.Product_CATEGORY_VALUES));
      localStorage.setItem('product_subcategory',JSON.stringify(constants.Product_SUBCATEGORY_VALUES));
      
      let settingResult = await SparkApi.fetchCompanySetting('lead_status_setting');
      if(settingResult && settingResult.setting){
        ////console.log('result status setting',settingResult.setting.configuration);
        localStorage.setItem('lead_status',settingResult.setting.configuration);

      }else{
        localStorage.setItem('lead_status',JSON.stringify(constants.LEAD_STATUS_VALUES));

      }
      //navigate("/");
      window.location.href = "/";
    } else {
      setShow(true);
      setErrorMessage(result.errors);
    }
  };

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const onLoginStart = (e) => {
    //setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const onLogoutSuccess = (e) => {
   // setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  // const onLoginStart = (e) => {
  //   setCredentials({ ...credentials, [e.target.name]: e.target.value });
  // };
  // const onLogoutSuccess = (e) => {
  //   setCredentials({ ...credentials, [e.target.name]: e.target.value });
  // };
  return (
    <Container className="d-flex justify-content-center align-items-center full-height">
     
      
   
      <Row className="login-form p-3 mt-5">
        <Col lg={6} className="pt-5">
          <img src="login.jpg" style={{ width: "100%" }} /> 
         
        </Col>
        <Col lg={6} className="login-section">
          <center></center>
          <div className="p-5 pt-4">
            <Form onSubmit={handleSubmit}>
              <div className="mt-2 text-center mb-3">
                <img
                  src="logo.png"
                  style={{ width: "120px" }}
                 className="mb-2"
                />
                 <div>Convert, before it fades away</div>
                <h3 className="my-2 mt-5">Login</h3>
              </div>
              <Alert variant="danger" show={show} className="error-alert">
                {errorMessage}
              </Alert>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  onChange={handleChange}
                  placeholder="Enter email"
                  
                  value={credentials.email}
                  style={validated ? {border: "1px solid #ced4da"} : {border: "1px solid tomato"}}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  onChange={handleChange}
                  placeholder="Password"
                  
                  value={credentials.password}
                  style={validated ? {border: "1px solid #ced4da"} : {border: "1px solid tomato"}}
                />
              </Form.Group>
              <div className="d-flex align-items-center">
              <Button variant="primary" type="submit" style={{padding: "8px"}}>
                Login
              </Button>
              <LoginSocialGoogle
          client_id='722921122303-0mlk9uoi35a6b41008tn4m1mdr1k3oo8.apps.googleusercontent.com'
          hosted_domain="ibirdsservices.com"
          onLoginStart={onLoginStart}
          redirect_uri='http://localhost:3000'
          scope="openid profile email"
          discoveryDocs="claims_supported"
          // access_type="offline"
          onResolve={({ provider, data }: IResolveParams) => {
            if(data.email_verified){
              handleSocialLogin(data);
            }
          }}
          onReject={err => {
            console.log(err);
          }}
        >
          <GoogleLoginButton style={{fontSize: "1rem", padding: "8px", height: "auto", boxShadow: "none", "border": "1px solid #ccc", borderRadius: "5px"}} />
        </LoginSocialGoogle>
        <LoginSocialFacebook
          appId='478406184817892'
          fieldsProfile={
            'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
          }
          onLoginStart={onLoginStart}
          onLogoutSuccess={onLogoutSuccess}
          redirect_uri='http://localhost:3000/logout'
          onResolve={({ provider, data }: IResolveParams) => {
            
            handleSocialLogin(data);
          }}
          onReject={err => {
            console.log(err);
          }}
        >
          <FacebookLoginButton style={{fontSize: "1rem", padding: "8px", height: "auto", boxShadow: "none", "border": "1px solid #ccc", borderRadius: "5px"}}/>
              </LoginSocialFacebook>
        </div>
            </Form>
            <div className="pt-4 text-center">
            {/* or Login with */}
            <div className="pt-4">
            
              {/* <div id="googlebutton">

              </div> */}


              
              
            </div>
            </div>
          </div>

          
        </Col>
        <Col>
        <div className="text-center">
      <div className="text-center mt-3"> Powered by</div> 
      <img src="indicrmlogo.png" width="150px"/>
      </div>
        </Col>
      </Row>
      
      
     
    </Container>
  );
};

export default Login;
