import { initializeApp } from 'firebase/app';
import { getFirestore, initializeFirestore, enableIndexedDbPersistence } from 'firebase/firestore';




// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAwPn5iUDQmhPuuhrObj3oqcfsmRQCJRmQ",
    authDomain: "ibirdscare-cfbace.firebaseapp.com",
    databaseURL: "https://ibirdscare-cfbace.firebaseio.com",
    projectId: "ibirdscare-cfbace",
    storageBucket: "ibirdscare-cfbace.appspot.com",
    messagingSenderId: "995755156252",
    appId: "1:995755156252:web:bded86acce74252e6d90d9"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore with experimentalForceLongPolling
const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,
  useFetchStreams: false, // optional, some environments might need this too
});

// Optional: Enable persistence
enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === 'failed-precondition') {
    console.error("Failed to enable persistence due to multiple tabs open");
  } else if (err.code === 'unimplemented') {
    console.error("Persistence is not available in this browser");
  }
});

export { db };